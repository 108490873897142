import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useMemo, useState } from "react";
import { ActivityIndicator, Text } from "react-native";
import { Extension } from "../extension";
import { API, APIGuideDTO } from "../api";
import { Layout } from "../components/Layout";
import { StackParamList } from "../../App";
import { Story } from "../components/Story";

type GuideProps = {
  route: RouteProp<StackParamList, "Guide">;
  navigation: StackNavigationProp<StackParamList, "Guide">;
};

export const GuideScreen: React.FC<GuideProps> = ({ route }) => {
  const extension = useMemo(() => Extension.parse(route.params.extension), [
    route.params.extension,
  ]);
  const [fetching, setFetching] = useState(true);
  const [guide, setGuide] = useState<APIGuideDTO | null>(null);
  const [sound] = useState(() => new Audio());

  useEffect(() => {
    if (!Extension.isValid(extension)) {
      return;
    }

    API.fetchGuide(extension).then((guide) => {
      setGuide(guide);
      setFetching(false);
    });
  }, [extension]);

  if (!Extension.isValid(extension)) {
    return (
      <Layout>
        <Text>Invalid extension</Text>
      </Layout>
    );
  }

  if (fetching) {
    return (
      <Layout>
        <ActivityIndicator />
      </Layout>
    );
  }

  if (!guide) {
    return (
      <Layout>
        <Text>Guide not found</Text>
      </Layout>
    );
  }

  if (!guide.current_story) {
    return (
      <Layout>
        <Text>Ingen historie lige nu</Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Story
        key={guide.current_story.id}
        story={guide.current_story}
        sound={sound}
      />
    </Layout>
  );
};
