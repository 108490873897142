import { Extension, ValidExtension } from "./extension";

export type APIGuideDTO = {
  area: AreaDTO;
  guide: GuideDTO;
  current_story: StoryDTO | null;
};

export type AreaDTO = {
  id: number;
  name: string;
};

export type GuideDTO = {
  id: number;
  name: string;
};

export type StoryDTO = {
  id: number;
  name: string;
  sections: SectionDTO[];
};

export type SectionDTO = {
  id: number;
  name: string;
  sounds: SoundDTO[];
  routes: RouteDTO[];
};

export type SoundDTO = {
  duration: number;
  mp3: string;
};

export type RouteDTO = {
  id: number;
  src: SourceDTO;
  dst: string;
};

export type SourceDTO =
  | { type: "fallback" }
  | { type: "no_choice" }
  | { type: "forward" }
  | SourceDigitDTO;

export type SourceDigitDTO = { type: "digit"; digit: number };

export const SourceDTO = {
  isDigit(src: SourceDTO): src is SourceDigitDTO {
    return src.type === "digit";
  },
};

export const API = {
  async fetchGuide(extension: ValidExtension): Promise<APIGuideDTO | null> {
    const url = new URL(
      "https://administration.audioguiden.dk/api/guides/by_extension"
    );
    url.searchParams.set("extension", Extension.value(extension));

    const response = await fetch(url.toString());

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      throw new Error("Could not fetch guide");
    }

    return response.json();
  },
};
