export type EmptyExtension = { type: "EmptyExtension" };

export type IncompleteExtension = {
  type: "IncompleteExtension";
  extension: string;
};

export type InvalidExtension = { type: "InvalidExtension"; extension: string };

export type ValidExtension = { type: "ValidExtension"; extension: string };

export type Extension =
  | EmptyExtension
  | IncompleteExtension
  | InvalidExtension
  | ValidExtension;

export const Extension = {
  parse(input: string): Extension {
    const extension = input.replace(/[^0-9]/g, "");

    if (extension.length === 0) {
      return Extension.empty();
    }

    if (extension.length < 8) {
      return {
        type: "IncompleteExtension",
        extension,
      };
    }

    if (extension.length > 8) {
      return { type: "InvalidExtension", extension };
    }

    return {
      type: "ValidExtension",
      extension,
    };
  },

  empty(): EmptyExtension {
    return { type: "EmptyExtension" };
  },

  value(extension: Extension): string {
    if (Extension.isEmpty(extension)) {
      return "";
    }

    return extension.extension;
  },

  isEmpty(extension: Extension): extension is EmptyExtension {
    return extension.type === "EmptyExtension";
  },

  isValid(extension: Extension): extension is ValidExtension {
    return extension.type === "ValidExtension";
  },
};
