import React, { useCallback } from "react";
import { Pressable, Text } from "react-native";
import tailwind from "tailwind-rn";
import { StoryDTO, RouteDTO, SourceDTO } from "../api";

export const SectionMenuItem: React.FC<{
  story: StoryDTO;
  route: RouteDTO;
  onSelect: (id: number) => void;
}> = ({ story, route, onSelect }) => {
  const section = story.sections.find(
    (section) => section.id === parseInt(route.dst)
  );

  const handlePress = useCallback(() => {
    if (section) {
      onSelect(section.id);
    }
  }, [onSelect, section]);

  if (!section) return null;

  const title = SourceDTO.isDigit(route.src)
    ? `${route.src.digit}. ${section.name}`
    : section.name;

  return (
    <Pressable
      style={tailwind("p-2 my-1 bg-indigo-500 rounded-lg")}
      onPress={handlePress}
    >
      <Text style={tailwind("text-center text-lg text-white")}>{title}</Text>
    </Pressable>
  );
};
