import React, { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import tailwind from "tailwind-rn";
import { SoundDTO } from "../api";

enum State {
  Loading = "LOADING",
  Loaded = "LOADED",
  Playing = "PLAYING",
  Paused = "PAUSED",
  Ended = "ENDED",
}

export const Sound: React.FC<{
  asound: HTMLAudioElement;
  sound: SoundDTO;
  autoplay: boolean;
  onFinish: () => void;
}> = ({ asound, sound, autoplay, onFinish }) => {
  const [state, setState] = useState(State.Loading);

  const handleLoadedEvent = useCallback(() => {
    setState(State.Paused);

    if (autoplay) asound.play();
  }, [autoplay, asound]);
  const handlePlayEvent = useCallback(() => setState(State.Playing), []);
  const handlePauseEvent = useCallback(() => setState(State.Paused), []);
  const handleEndedEvent = useCallback(() => {
    setState(State.Ended);
    onFinish();
  }, [onFinish]);

  const handlePlay = useCallback(() => asound.play(), [asound]);
  const handlePause = useCallback(() => asound.pause(), [asound]);

  useEffect(() => {
    asound.addEventListener("canplaythrough", handleLoadedEvent, {
      once: true,
    });
    asound.addEventListener("play", handlePlayEvent);
    asound.addEventListener("pause", handlePauseEvent);
    asound.addEventListener("ended", handleEndedEvent);
    return () => {
      asound.removeEventListener("canplaythrough", handleLoadedEvent);
      asound.removeEventListener("play", handlePlayEvent);
      asound.removeEventListener("pause", handlePauseEvent);
      asound.removeEventListener("ended", handleEndedEvent);
    };
  }, [
    asound,
    handleLoadedEvent,
    handlePlayEvent,
    handlePauseEvent,
    handleEndedEvent,
  ]);

  useEffect(() => {
    asound.src = "https://administration.audioguiden.dk" + sound.mp3;
    asound.load();

    return () => {
      asound.pause();
    };
  }, [asound, sound]);

  if (state === State.Loading) {
    return (
      <SoundButton>
        <ActivityIndicator color="#ffffff" />
      </SoundButton>
    );
  }

  return state === State.Playing ? (
    <SoundButton onPress={handlePause} title="Pause" />
  ) : (
    <SoundButton onPress={handlePlay} title="Spil" />
  );
};

const SoundButton: React.FC<{
  onPress?: () => void;
  title?: React.ReactNode;
}> = ({ onPress, title, children }) => {
  const style = tailwind(
    "rounded-full w-24 h-24 flex items-center justify-center bg-indigo-500"
  );

  return (
    <View style={tailwind("flex items-center")}>
      <Pressable style={{ ...style }} onPress={onPress}>
        {title && <Text style={tailwind("text-white text-lg")}>{title}</Text>}
        {children}
      </Pressable>
    </View>
  );
};
