import React, { useCallback, useMemo } from "react";
import { Text, Pressable, View } from "react-native";
import tailwind from "tailwind-rn";
import { StoryDTO, SectionDTO } from "../api";
import { SectionMenuItem } from "./SectionMenuItem";

export const SectionMenu: React.FC<{
  story: StoryDTO;
  section: SectionDTO;
  onSelect: (id: number) => void;
}> = ({ story, section, onSelect }) => {
  const routes = useMemo(
    () => section.routes.filter((route) => route.src.type !== "no_choice"),
    [section]
  );

  const handleSelectDefault = useCallback(
    () => onSelect(story.sections[0].id),
    [story, onSelect]
  );

  if (routes.length === 0) {
    return (
      <Pressable
        style={tailwind("p-2 my-1 bg-indigo-500 rounded-lg")}
        onPress={handleSelectDefault}
      >
        <Text style={tailwind("text-center text-lg text-white")}>
          Tilbage til start
        </Text>
      </Pressable>
    );
  }

  return (
    <View>
      {routes.map((route) => (
        <SectionMenuItem
          key={route.id}
          story={story}
          route={route}
          onSelect={onSelect}
        />
      ))}
    </View>
  );
};
