import { Link, RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, TextInput, View } from "react-native";
import tailwind from "tailwind-rn";
import { Extension } from "../extension";
import { API, APIGuideDTO } from "../api";
import { Layout } from "../components/Layout";
import { StackParamList } from "../../App";

type LookupProps = {
  route: RouteProp<StackParamList, "Lookup">;
  navigation: StackNavigationProp<StackParamList, "Lookup">;
};

export const LookupScreen: React.FC<LookupProps> = () => {
  const [extension, setExtension] = useState<Extension>(Extension.empty());
  const [fetching, setFetching] = useState(false);
  const [guide, setGuide] = useState<APIGuideDTO | null>(null);

  useEffect(() => {
    if (!Extension.isValid(extension)) {
      setGuide(null);
      return;
    }

    setFetching(true);

    API.fetchGuide(extension).then((guide) => {
      setGuide(guide);
      setFetching(false);
    });
  }, [extension, setGuide, setFetching]);

  const handleSetExtension = useCallback(
    (extension: string) => {
      setExtension(Extension.parse(extension));
    },
    [setExtension]
  );

  return (
    <Layout>
      <View style={tailwind("flex flex-row")}>
        <TextInput
          value={Extension.value(extension)}
          onChangeText={handleSetExtension}
          style={tailwind(
            "flex-1 bg-indigo-100 rounded-lg p-2 text-lg text-center"
          )}
          placeholder="Indtast nummer"
          keyboardType="number-pad"
        />
      </View>

      <View style={tailwind("mt-2")}>
        {fetching && (
          <ActivityIndicator style={tailwind("p-2")} color="#ffffff" />
        )}

        {!fetching && guide && (
          <Link
            to={`/guide/${Extension.value(extension)}`}
            style={tailwind(
              "text-center text-lg p-2 bg-indigo-500 rounded-lg text-white"
            )}
          >
            {guide.guide.name}
          </Link>
        )}
      </View>
    </Layout>
  );
};
