import React, { useCallback, useState } from "react";
import { Text, View } from "react-native";
import tailwind from "tailwind-rn";
import { StoryDTO } from "../api";
import { Section } from "./Section";

export const Story: React.FC<{ story: StoryDTO; sound: HTMLAudioElement }> = ({
  story,
  sound,
}) => {
  const [sectionIdx, setSectionIdx] = useState(0);
  const section = story.sections[sectionIdx];
  const [autoplay, setAutoplay] = useState(false);

  const handleSetSection = useCallback(
    (id: number) => {
      const idx = story.sections.findIndex((section) => section.id === id);

      if (idx >= 0) setSectionIdx(idx);

      // When a section is set because of a menu choice, the
      // sound should autoplay.
      setAutoplay(true);
    },
    [story]
  );

  if (!section) {
    return <Text>Kunne ikke finde sektion</Text>;
  }

  return (
    <View style={tailwind("flex-1 flex")}>
      <Text style={tailwind("text-white text-3xl text-center mb-4")}>
        {story.name}
      </Text>
      <Text style={tailwind("text-white text-2xl text-center")}>
        {section.name}
      </Text>
      <Section
        key={section.id}
        sound={sound}
        story={story}
        section={section}
        autoplay={autoplay}
        setSection={handleSetSection}
      />
    </View>
  );
};
