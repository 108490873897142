import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import tailwind from "tailwind-rn";
import { LookupScreen } from "./src/screens/LookupScreen";
import { GuideScreen } from "./src/screens/GuideScreen";

export type StackParamList = {
  Lookup: { extension: string };
  Guide: { extension: string };
};

const Stack = createStackNavigator<StackParamList>();

const linking: LinkingOptions = {
  prefixes: [],
  config: {
    initialRouteName: "Lookup",
    screens: {
      Lookup: "",
      Guide: "/guide/:extension",
    },
  },
};

const App: React.FC = () => {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerStyle: tailwind("bg-indigo-800 border-0"),
          headerTintColor: "#fff",
          title: "Audioguiden",
        }}
      >
        <Stack.Screen name="Lookup" component={LookupScreen} />
        <Stack.Screen name="Guide" component={GuideScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
