import React, { useCallback, useState } from "react";
import { Pressable, Text, View } from "react-native";
import tailwind from "tailwind-rn";
import { StoryDTO, SectionDTO } from "../api";
import { SectionMenu } from "./SectionMenu";
import { Sound } from "./Sound";

export const Section: React.FC<{
  sound: HTMLAudioElement;
  story: StoryDTO;
  section: SectionDTO;
  autoplay: boolean;
  setSection: (id: number) => void;
}> = ({ sound: asound, story, section, autoplay, setSection }) => {
  const [soundIdx, setSoundIdx] = useState(0);
  const sound = section.sounds[soundIdx];
  const [localAutoplay, setLocalAutoplay] = useState(false);

  const handlePlayPrevious = useCallback(() => {
    if (soundIdx > 0) {
      setLocalAutoplay(true);
      setSoundIdx(soundIdx - 1);
    }
  }, [soundIdx]);

  const handlePlayNext = useCallback(() => {
    if (soundIdx < section.sounds.length - 1) {
      setLocalAutoplay(true);
      setSoundIdx(soundIdx + 1);
    }
  }, [section, soundIdx]);

  return (
    <View style={tailwind("flex-1 flex")}>
      <View style={tailwind("flex-1 flex justify-center items-center")}>
        {sound && (
          <View style={tailwind("flex flex-row items-center")}>
            {soundIdx > 0 ? (
              <Pressable
                onPress={handlePlayPrevious}
                style={tailwind(
                  "bg-indigo-600 rounded-full w-8 h-8 flex justify-center items-center text-white mr-2"
                )}
              >
                <Text>{"<<"}</Text>
              </Pressable>
            ) : (
              <View style={tailwind("w-8 mr-2")}></View>
            )}
            <Sound
              asound={asound}
              key={soundIdx}
              sound={sound}
              autoplay={localAutoplay || autoplay}
              onFinish={handlePlayNext}
            />
            {soundIdx < section.sounds.length - 1 ? (
              <Pressable
                onPress={handlePlayNext}
                style={tailwind(
                  "bg-indigo-600 rounded-full w-8 h-8 flex justify-center items-center text-white ml-2"
                )}
              >
                <Text>{">>"}</Text>
              </Pressable>
            ) : (
              <View style={tailwind("w-8 ml-2")}></View>
            )}
          </View>
        )}
        {section.sounds.length > 1 && (
          <Text style={tailwind("text-gray-200 p-1")}>
            ({soundIdx + 1}/{section.sounds.length})
          </Text>
        )}
      </View>
      <SectionMenu story={story} section={section} onSelect={setSection} />
    </View>
  );
};
